<template lang="pug">
ui-loader(v-if="count === 0 && isLoading")
.list-wrapper(
  v-if="count > 0"
  v-infinite-scroll="loadNextPart"
  :infinite-scroll-immediate="false"
)
  slot
  ui-loader(v-if="isLoading")
  .result(v-else-if="isOver") {{ resultText }}: {{ count }}

no-data(
  v-if="!isLoading && (info.meta.error || count === 0)"
  :text="noDataText"
  :description="description"
  :show-reload-button="info.meta.error && info.meta.part > 0"
  @reload="$emit('reload')"
)
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { getEntityListInfo } from "@/utils/getters/defaultRequestInfo";

import type { PropType } from "vue";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import NoData from "@/components/ui/table/NoData.vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

export default defineComponent({
  name: "ListWrapper",
  components: {
    UiLoader,
    NoData,
  },
  emits: [
    'reload',
    'loadNextPart',
  ],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object as PropType<MainStreamListInfo>,
      default: getEntityListInfo(),
    },
    noDataText: {
      type: String,
      default: 'Нет данных',
    },
    resultText: {
      type: String,
      default: 'Количество результатов',
    },
    inAnalytics: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const isLoading = computed(() => props.info.meta.loading)
    const isOver = computed(() => props.info.meta.isOver)

    const description = computed(() => props.info.meta.error ? 'error' : (!props.inAnalytics && props.info.meta.mode === 'newSearch' ? 'filters' : 'none'))

    function loadNextPart() {
      if (!props.info.meta.loading && !props.info.meta.error && !props.info.meta.isOver) {
        context.emit('loadNextPart')
      }
    }

    return {
      isLoading,
      isOver,
      description,
      loadNextPart,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/lotsList";

.list-wrapper {
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin-bottom: 16px;
}
</style>
